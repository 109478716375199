.header {
    height: 113px;
    width: 100%;
    background: url("../../../static/image/headerbg.png");
    background-size: cover;
    padding: 0 24px;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    font-weight: 400;
    color: #358FE3;
    font-size: 16px;
    &__title {
        flex: 1;
        font-size: 38px;
        font-family: electronicFont;
        text-stroke: 1px #3f91d8;
        -webkit-background-clip: text;
        line-height: 42px;
        color: #B0D8F5;
        letter-spacing: 6px;
        text-align: center;
        line-height: 113px;
    }
    &__right,&__left{
        display: flex;
        align-items: center;
        flex: 1;
        height: 88px;

    }
    &__left{
        >div{
            margin-right: 40px;
        }
        &--site{
            width: 110px;
            height: 40px;
            >img{
                width: 100%;
                height: 100%;
            }
        }
    }
   
    &__right{
        justify-content: flex-end;
        >span{
            display: inline-block;
            margin-left: 37px;
        }
        >span:last-child{
            width: 150px;
            margin-left: 0;
            color: #fff;
            text-align: right;
            font-size: 24px;
            letter-spacing: 2px;
            font-family: electronicFont;
        }
    }
}
