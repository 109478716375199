.surfaceClass {
    width: 170px;
    height: 160px;
    margin: 0 auto;
}
.environment{
    width: 465px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    &__tall{
        text-align: center;
        p{
            padding-top: 25px;
            color: #3F91D8;
            font-size: 14px;
        }
        span{
            font-size: 20px;
            display: inline-block;
            color: #fff;
            padding-top: 20px;
        }
    }
    &__time{
        font-size: 12px;
        color: #fff;
        padding-top: 30px;
        font-weight: bold;
        padding-left: 20px;
    }
    &__describe{
        font-size: 12px;
        color: #fff;
        font-weight: bold;
        p{
            padding: 5px 0 0 20px;
        }
    }
    &__conten{
        background: url('../../../../static/image/atlas.png');
        background-size: cover;
        width: 100%;
        height: 106px;
        position: relative;
        &__box{
            position: absolute;
            top: 4px;
            left: 50%;
            transform: translateX(-50%);
            width: 92px;
            height: 92px;
            background: url('../../../../static/image/ld.gif');
            background-size: cover;
        }
        
    }
    &__header{
        display: flex;
        font-size: 14px;
        color: #fff;
        align-items: center;
        padding: 16px 0 8px 20px;
        >span{
            display: inline-block;
            padding-left: 8px;
            font-weight: bold;
        }
        &--icon{
            width: 18px;
            height: 18px;
            >img{
                width: 100%;
                height: 100%;
            }
        }
    }
    >div{
        width: 223px;
        height: 200px;
        background: url('../../../../static/image/imbg.png');
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        >img{
            position: absolute;
            width: 100%;
            height: 100%;
        }
        >img:first-child{
            width: 90% !important;
        }
    }
    >div:first-child{
        
    }
}