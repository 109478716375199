.speed {
    width: 450px;
    height: 260px;
    margin: 16px auto 16px auto;
}
.proceed {
    &__title {
        font-size: 16px;
        color: #266bf2;
        padding: 16px 0 10px 60px;
    }
    &__box {
        width: 468px;
        height: 126px;
        margin: 0 auto;
        background: url("../../../../static/image/proceed.png");
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
            flex: 1;
        }
    }
    &__left {
        p {
            color: #4992ff;
        }
        span {
            color: #0170fe;
        }
    }
    &__conten {
        text-align: center;
        p {
            color: #99b3c8;
            font-size: 16px;
            padding-top: 15px;
        }
        span {
            display: inline-block;
            padding-top: 3px;
            color: #b0d8f5;
            font-size: 20px;
            text-shadow: 0px 0px 16px #3f91d8;
        }
    }
    &__left {
        &--icon {
            left: 23px;
        }
        &--conten {
            left: 73px;
            span{
                color: #3B3FE1;
                text-shadow: 0px 0px 16px #3B3FE1;
                animation: rightshaow 3s infinite linear;
            }
        }
    }
    &__right {
        &--icon {
            right: 23px;
        }
        &--conten {
            right: 70px;
            text-shadow: 0px 0px 16px #3b3fe1;
            p {
                color: #0093d3;
            }
            span {
                color: #3bd7e1;
                text-shadow: 0px 0px 16px #3bd7e1;
                animation: leftshaow 2s infinite linear;
            }
        }
    }
    &__right,
    &__left {
        display: flex;
        position: relative;
        &--icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 64px;
            height: 72px;
            > img {
                width: 100%;
                height: 100%;
            }
        }
        &--conten {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 120px;
            text-align: center;
            padding-top: 10px;
            p {
                font-size: 14px;
            }
            span {
                display: inline-block;
                font-size: 18px;
                padding-top: 5px;
                text-align: center;
            }
        }
    }
}
@keyframes leftshaow {
    0% {
        text-shadow: 0px 0px 5px #3bd7e1;
    }
    25% {
        text-shadow: 0px 0px 10px #3bd7e1;
    }
    50% {
        text-shadow: 0px 0px 16px #3bd7e1;
    }
    75% {
        text-shadow: 0px 0px 10px #3bd7e1;
    }
    100% {
        text-shadow: 0px 0px 5px #3bd7e1;
    }
}

@keyframes rightshaow {
    0% {
        text-shadow: 0px 0px 5px #3B3FE1;
    }
    25% {
        text-shadow: 0px 0px 10px #3B3FE1;
    }
    50% {
        text-shadow: 0px 0px 16px #3B3FE1;
    }
    75% {
        text-shadow: 0px 0px 10px #3B3FE1;
    }
    100% {
        text-shadow: 0px 0px 5px #3B3FE1;
    }
}
