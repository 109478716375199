.sections {
    display: flex;
    .column {
        flex: 3;
    }
    .column:nth-child(2) {
        flex: 5;
    }
    .column__voluntarily {
        display: flex;
        align-items: center;
        background: rgba(255, 255, 255, 0.01);
        box-shadow: inset 0px 2px 50px 0px rgba(23, 156, 255, 0.7);
        width: 466px;
        height: 180px;
        margin: 10px auto;
        display: flex;
        padding: 0 30px;
        align-items: center;
        justify-content: space-between;
        position: relative;
        >img{
            position: absolute;
            width: 90%;
            height: 100%;
            z-index: -1;
        }
    }
}
