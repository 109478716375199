.banner{
    color: #B0D8F5;
    width: 466px;
    height: 34px;
    background: url('../../../static/image/banner.png');
    background-size: cover;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 10px;
    >span{
        padding-left: 61px;
        font-size: 13px;
    }
}