.strip{
    width: 466px;
    height: 40px;
    background: url('../../../static/image/strip.png');
    background-size: cover;
    color: #B0D8F5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    >span{
        font-family: electronicFont;
        font-size: 20px;
        text-shadow: 0px 4px 16px #3F91D8;
    }
    >span::before,>span::after{
        content: '·';
        font-size: 30px;
        line-height: 3px;
        padding:0 10px;
        position: relative;
        top: 3px;
        color: #EAB309;
    }
    position: relative;
   
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
