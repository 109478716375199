.pressure{
    width: 443px;
    margin: 10px auto 0 auto;
    position: relative;
    height: 50px;
    &__schedule{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >div{
            background: linear-gradient(90deg, #D8D8D8 0%, #D8D8D8 66%, #1B2A40 67%);
            width: 8px;
            height: 10px;
        }
       &-s{
         transition: 0.3s;
         background: linear-gradient(90deg, #4992FF 0%, #4FDFFF 66%, #1B2A40 67%) !important;
       }
    }
    &__limit{
        width: 100%;
        height: 6px;
        background:#1B2940;
        margin: 6px 0;
    }
    &__enter{
        position: absolute;
        color: #4EDFFF;
        font-size: 16px;
        bottom: 5px;
        font-family: electronicFont;
        text-shadow: 0px 0px 8px rgba(78,223,255,0.7);
        text-align: center;
        display: flex;
        align-items: flex-end;
        transition: 0.3s;
        >span{
            display: inline-block;
        }
        &--icon{
            width: 11px;
            height: 17px;
            margin: 0 auto;
            >img{
                width: 100%;
                height: 100%;
            }
        }
    }
}